<template>
  <div
    v-if="item.discount?.price"
    class="shop-product-card-discount__flag"
    :class="{ 'is-hero': isHero }"
  >
    <span v-if="item.discount?.type === 'Fixed Amount'">-</span>
    {{ readableAmount }}
    <span v-if="item.discount?.type === 'Percentage'">%</span>
    <span v-if="item.discount?.type === 'Fixed Amount'">CHF</span>
  </div>
</template>

<script setup>
defineProps({
    isHero: {
        type: Boolean,
        default: false,
    },
});

const item = inject('item');
const discount = computed(() => item?.discount || item?.value?.discount);
const readableAmount = computed(() => {
    switch (discount?.value?.type) {
    case 'Fixed Amount':
        return parseFloat(discount.value.amountToDiscount).toFixed(2);
    default:
        return discount.value.amountToDiscount;
    }
});
</script>

<style lang="scss" scoped>
.shop-product-card-discount__flag {
    @include typo-font('regular');

    position: absolute;
    right: 20px;
    bottom: 20px;
    width: auto;
    padding: 5px 10px;
    border-radius: 3px;
    background: $C_RED;
    color: $C_WHITE;
    font-size: 14px;
    pointer-events: none;

    &.is-hero {
        @include typo-size('nav');

        position: unset;
        display: flex;
        align-items: center;
        padding: 0;
        padding: 15px 18px;
        border-radius: 6px;
        margin-right: 10px;
    }
}
</style>
